import React from 'react'
import {HashRouter, Route, Switch} from 'react-router-dom'
import './styles/style.css'

import { INGRESO_ROUTE } from './config/routes'
import { SELECCION_ROUTE } from './config/routes'

import Ingreso from './components/ingreso.component'
import Seleccion from './components/seleccion.component'

class App extends React.Component{
  render(){
    return(
      <HashRouter>
          <Switch>
            <Route path = {INGRESO_ROUTE} component = {Ingreso} exact/>
            <Route path = {SELECCION_ROUTE} component = {Seleccion} exact/>
          </Switch>
      </HashRouter>
    )
  }
}

export default App