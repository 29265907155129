import React from 'react'
import { Link } from "react-router-dom";
import ReactPlayer from 'react-player'
import ReactAudioPlayer from 'react-audio-player';

import IntroVideo from './../Assets/Video/video-intro.mp4'

import music from './../Assets/Music/testSong.mp3'
import musicOgg from './../Assets/Music/testSongOgg.ogg'

import { SELECCION_ROUTE } from "./../config/routes";

import background from './../UI/bg.jpg'
import loading from './../UI/loading.png'

import logoEspacioA from './../UI/espacio.svg'
import blueHR from './../UI/hr.png'
import LogoAlumina from './../UI/alumina.svg'

import muted from './../UI/muted.svg'
import unmuted from './../UI/unmuted.svg'

class Ingreso extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            videoReady: false,
            iter: -1,
            isAdding: true,
            isMainVideo: false,
            isVideoEnded: false,
            isVideoLoading: true,
            isMainVisible: false,
            isIngreoVisible: false,
            startLoadingVideo: false,
            isMuted: false,
            isFinishLoading: false
        }
    }

    componentDidMount(){
        this.setState({
            startLoadingVideo: true
        })
        var video = document.getElementById("video")
        video.load()
        this.handleVideoLoadingEnd()
    }

    handleVideoReady = () => {
        this.setState({
            videoReady: true 
        })
    }

    handleAddToProgress = () => {
        this.setState({
            isFinishLoading: true
        })
    }

    handleVideoEnded = () => {
        this.setState({
            isVideoEnded: true
        })
        setTimeout(function(){this.setState({
            isMainVisible:true,
            isIngresoVisible:true,
        })}.bind(this), 500)

        setTimeout(function(){this.setState({
            isFirst: true
        })}.bind(this), 1000)

        setTimeout(function(){this.setState({
            isSecond: true
        })}.bind(this), 1500)

        setTimeout(function(){this.setState({
            isThird: true
        })}.bind(this), 2000)
    }

    handleMuteMusic = () => {
        this.setState({
            isMuted: !this.state.isMuted
        })
    }

    handleBotonIngresar = () => {
        var vid = document.getElementById("video")
        vid.play()
        this.setState({
            isLoading: false,
            isMainVideo: true,
            isFinishLoading: false
        })
    }

    handleVideoLoadingEnd = () => {
        this.interval = setInterval(() => this.tick(), 1000);
    }

    tick() {
        if(this.state.iter < 7){
            this.setState({
                iter: this.state.iter + 1
            })
        }
        else{
            setTimeout(function(){this.setState({
                isFinishLoading: true
            })}.bind(this), 500)
            clearInterval(this.interval)
        }
    }

    handleProgress = (event) => {
        console.log(event)
    }

    render(){
        return(
            <div className="lading-page">
                {
                    <div className="video-container">
                        <video
                            muted
                            playsInline
                            src={IntroVideo}
                            preload={'auto'}
                            type={'video/mp4'}
                            className={"video"}
                            id={"video"}
                            ref={ref => this.headerVideo}
                            //onLoadedData={() => {this.handleVideoLoadingEnd()}}
                            onEnded={() => {this.handleVideoEnded()}}
                            >
                        </video>
                    </div>
                }
                {
                    this.state.isMainVideo ? 
                    <audio autoPlay loop muted={this.state.isMuted}>
                        <source src={music} type="audio/mpeg"/>
                    </audio>: null
                }
                {
                    this.state.isMainVideo ?
                    <div className="btn-mute-container">
                        <button className="mute-btn" onClick = {() => {this.handleMuteMusic()}}>
                            <img src={this.state.isMuted ? muted:unmuted}></img>
                        </button>
                    </div>: null
                }

                
                {
                    this.state.isLoading ? 
                    <div className="loading-screen">
                        <div className="background-container">
                            <img src={background} className="background"></img>
                        </div>
                        <p className={"loading-title"}>Tenemos preparada una gran experiencia para ti</p>
                        <img className="logo-espacio-intro" src={logoEspacioA}></img>
                        <img className="logo-alumina-intro" src={LogoAlumina}></img>
                        {
                        <div className={this.state.isFinishLoading ? "invisible":"progress-bar"}>
                            <img src={loading} className={this.state.iter >= 1 ? "visible":"invisible"}></img>
                            <img src={loading} className={this.state.iter > 1 ? "visible":"invisible"}></img>
                            <img src={loading} className={this.state.iter > 2 ? "visible":"invisible"}></img>
                            <img src={loading} className={this.state.iter > 3 ? "visible":"invisible"}></img>
                            <img src={loading} className={this.state.iter > 4 ? "visible":"invisible"}></img>
                            <img src={loading} className={this.state.iter > 5 ? "visible":"invisible"}></img>
                            <img src={loading} className={this.state.iter > 6 ? "visible":"invisible"}></img>
                        </div>
                        }
                    </div>:<></>
                }
                {
                this.state.isFinishLoading ?
                    <div className="btn-ingreso centered" onClick={() => {this.handleBotonIngresar()}}>
                        <span>¡Ingresa!</span>
                    </div>: null
                }                
                {
                    this.state.isVideoEnded ? 
                    <div className={this.state.isMainVisible ? "main-visible":"main-invisible"}>
                        <div className={this.state.isIngresoVisible ? "ingreso-visible":"ingreso-invisible"}>
                            <div className={this.state.isFirst ? "top-container visible":"top-container invisible"}>
                                <img className="logo-espacio" src={logoEspacioA}></img>
                                <img className="hr" src={blueHR}></img>
                                <div className="main-text-container">
                                    <p className="ingreso-title">¡BIENVENIDO A NUESTRO SHOWROOM VIRTUAL!</p>
                                    <p className="ingreso-text">Aquí podrás interactuar y conocer en detalle las características y beneficios <b>de nuestros sistemas diferenciados en cerramientos arquitectónicos, diseñados para tu hogar o tus proyectos.</b></p>
                                </div>
                            </div>
                            <Link
                                to={{
                                    pathname: SELECCION_ROUTE
                                }}
                                className={this.state.isThird ? "btn-ingreso visible":"btn-ingreso invisible"}
                                >
                                <span>¡VIVE LA EXPERIENCIA!</span>
                            </Link>
                            <img className={this.state.isSecond ? "logoAlumina visible":"logoAlumina invisible"} src={LogoAlumina}></img>
                        </div>
                    </div>
                    :null
                }
            </div>
        )
    }
}

export default Ingreso