import React from 'react'

import background2 from './../UI/bg2.jpg'
import lineaKoncept from './../UI/lineaKoncept.jpg'
import lineaSuperior from './../UI/lineaSuperior.jpg'
import square from './../UI/square.png'
import logoEspacioA from './../UI/espacioA.png'
import blueHR from './../UI/hr.png'
import LogoAlumina from './../UI/logoAlumina.png'

import music from './../Assets/Music/testSong-2.mp3'
import music2 from './../Assets/Music/testSong-3.mp3'
import music3 from './../Assets/Music/testSong-4.mp3'

import muted from './../UI/muted.png'
import unmuted from './../UI/unmuted.png'

import back from './../UI/back.png'

import { Link } from 'react-router-dom'

import { INGRESO_ROUTE } from "./../config/routes";

const playlist = [
    music,
    music2,
    music3,
]

class Seleccion extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isMuted: false,
            currentSong: playlist[0],
            currentSongNumber: 0
        }
    }

    handleMuteMusic = () => {
        this.setState({
            isMuted: !this.state.isMuted
        })
    }

    handleNextSong = () => {
        console.log("song ended")
        this.setState(prevState => ({
            currentSongNumber: (prevState.currentSongNumber + 1) % 3,
            currentSong: playlist[(prevState.currentSongNumber + 1) % 3]
        }), () => {
            console.log(this.state.currentSongNumber)
        })
    }

    render(){
        
        return(
            <div className="seleccion-page">
                {/*<audio src={this.state.currentSong} autoPlay muted={this.state.isMuted} onEnded={() => {this.handleNextSong()}}></audio>*/}
                <img src={background2} className="seleccion-bg" alt=""></img>
                {/*<div className="btn-mute-container responsive-btn">
                        <button className="mute-btn" onClick = {() => {this.handleMuteMusic()}}>
                            <img src={this.state.isMuted ? muted:unmuted}></img>
                        </button>
                </div>*/}
                <div className="back-btn">
                <Link to={{pathname: INGRESO_ROUTE}}><img src={back} className="back-img" alt=""></img></Link>
                </div>
                <div className="seleccion-image-container"></div>
                <div className="seleccion-container">
                    <div className="seleccion-component">
                        <div className="text-seleccion-container linea-koncept">
                            <div className="nombre-linea">
                                <img src={square} alt=""></img>
                                <p>LÍNEA <b>KONCEPT</b></p>
                            </div>
                            <div className="informacion-linea">
                                <p><b>Línea premium</b> que se destaca por su eficiencia, desempeño, estética y versatilidad, ideal para resolver proyectos de las más altas especificaciones.</p>
                            </div>
                            <div className="boton-linea">
                                <a className="btn-conoce" href={'./recorridos/koncept/index.html'}>Conoce más</a>
                            </div>
                        </div>
                        <div className="image-zoom-container">
                        <a href={'./recorridos/koncept/index.html'}><img className="seleccion-images images-koncept" src={lineaKoncept} alt="">
                            </img></a>
                        </div>
                    </div>
                    <div className="seleccion-component">
                        <div className="text-seleccion-container linea-superior">
                            <div className="nombre-linea">
                                <img src={square} alt=""></img>
                                <p>LÍNEA <b>SUPERIOR</b></p>
                            </div>
                            <div className="informacion-linea informacion-superior">
                                <p><b>Línea versátil</b>, de ensambles simples, que brindan confort superior en comparación con los sistemas tradicionales del mercado, ideal para todo tipo de proyectos.</p>
                            </div>
                            <div className="boton-linea">
                                <a className="btn-conoce" href={'./recorridos/superior/index.html'}>Conoce más</a>
                            </div>
                        </div>
                        <div className="image-zoom-container">
                            <a href={'./recorridos/superior/index.html'}><img className="seleccion-images" src={lineaSuperior} alt="">
                            </img></a>
                        </div>
                    </div>
                </div>
                <div className="logos-container">
                    <Link to={{pathname: INGRESO_ROUTE}}><img src={logoEspacioA}></img></Link>
                    <Link to={{pathname: INGRESO_ROUTE}}><img src={LogoAlumina}></img></Link>
                </div>
            </div>
        )
    }
}

export default Seleccion